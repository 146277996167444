.footer {
  padding-top: 40px;
  padding-bottom: 30px;
  margin-top: 100px;
  text-align: center;
  font-size: 12px;
}

.footer-social {
  margin-bottom: 10px;
}

.footer-social-buttons {
  display: inline-block;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.footer-social-buttons li {
  display: inline-block;
  line-height: 1;
}

.footer-social-buttons li + li {
  margin-left: 15px;
}

.footer-social-buttons .twitter-follow-button {
  width: 225px !important;
}

.footer-social-buttons .twitter-share-button {
  width: 98px !important;
}
