body {
  padding-top: 60px;
}

th.icon {width: 1%;}

td.icon {width: 1%;}

/* override bootstrap */
.col-form-label {
    text-align: right;
}

/* font awesome color, pointer, and hover */
.fa-cph {
  color: #2780E3;
  cursor: pointer;
}

.fa-cph:hover {
  color: #165ba8;
}

/* loader for button */
@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid;
  animation: load 1s infinite linear;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

/* sidebar */
.sidebar-box {
  margin: 21px 0 20px;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  border-left: 1px solid #eee;
  border-top: 1px solid #eee;
  border-right: 2px solid #eee;
  border-bottom: 2px solid #eee;
}

.sidebar .user {
  border-bottom: 1px solid #dfd7ca;
  padding-bottom: 6px;
  text-align: center;
}

.sidebar .user a {
  color: #999;
  padding: 8px 10px;
  display: block;
}

.sidebar .user a:hover, a:focus {
  color: #555;
  text-decoration: none;
}

.sidebar .user a:active, a:hover {
  outline: 0;
}

.sidebar .user ul.nav > li {
  margin: 0;
  border-bottom: unset;
}

.sidebar .user .nav > li > a {
  font-size: 13px;
  line-height: 20px;
  padding: 10px 10px;
  color: #999;
  display: block;
  font-weight: normal;
  background: none;
  text-decoration: none;
  border-top: 0px;
}

.sidebar .nav > li {
  border-bottom: 1px dashed #eee;
}

.sidebar .nav > li:last-child {
  border-bottom: 0px;
}

.sidebar .nav > li li {
  margin: 0;
}

.sidebar .nav > li li a {
  padding-left: 25px;
}

.sidebar .nav > li > a {
  padding: 15px 9px;
  color: #999;
  display: block;
  font-weight: bold;
  text-decoration: none;
}

.sidebar .nav > li > a > i {
  margin-right: 5px;
}

.sidebar .nav > li .submenu > ul > li > a {
  font-size: 13px;
  line-height: 20px;
  padding: 8px 10px 8px 35px;
  color: #999;
  background: #fff;
  display: block;
  text-decoration: none;
  border-top: 0px;
  font-weight: normal;
}

.sidebar .nav > li .submenu > ul > li.active > a {
  background: #fff;
  border-top: 0px;
  color: #555;
}

.sidebar .nav > li .submenu > ul > li > a:hover {
  background: #fff;
  color: #555;
  border-bottom: 0px;
}

.sidebar .nav li a:hover {
  background: #fff;
  color: #555;
  border-bottom: 0px;
}

.sidebar .user a .caret, .sidebar .nav a .caret {
  width: 0;
  height: 0;
  display: inline-block;
  vertical-align: top;
  border-top: 4px solid #aaa;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  margin-top: 8px;
  margin-left: 2px;
}

.sidebar .user a:hover .caret, .sidebar .nav a:hover .caret {
  border-top-color: #aaa;
}

/* sidebar active */
.sidebar .nav > li > a.active {
  background: #fff;
  color: #555;
  border-bottom: 0px;
}

/** dashboard **/
.dashboard-card {
  border-radius: 6px;
  box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);
  background-color: #FFFFFF;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.dashboard-card .heading {
  padding: 20px 20px 0;
}

.dashboard-card .heading a {
  font-weight: 400;
  font-size: 11px;
}

.dashboard-card .title {
  margin: 0;
  color: #252422;
  font-weight: 300;
}

.dashboard-card .category {
  font-size: 14px;
  font-weight: 400;
  color: #9A9A9A;
  margin-bottom: 0px;
}

.dashboard-card .content {
  padding: 15px 20px 10px 20px;
}

.dashboard-card h4 {
  line-height: 1.2em;
}

.dashboard-card .footing {
  padding: 0 15px 15px;
}

.dashboard-card .footing .footing-title {
  padding-top: 5px;
  display: inline-block;
}

.dashboard-card .stats {
  color: #a9a9a9;
  font-weight: 300;
}
